/* ------------------------------------------------------------ */
/** Navigation Header Styling                                  **/
/* ------------------------------------------------------------ */

.NavHeader-Container {
    display: flex;
    align-items: center;
    background-color: var(--darkgrey);
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 100;
    padding: 12px 30px;
    height: 100%;
    width: 100%;
    min-width: 375px;
}

/* ------------------------------------------- */
/** Nav Header Title                          **/
/* ------------------------------------------- */

.NavHeader-Title {
    font-weight: 400;
    font-size: 1.35rem;
    margin: 10px 0px;
    cursor: pointer;
}

/* ------------------------------------------- */
/** Menu & Logout Dropdown                     **/
/* ------------------------------------------- */

.NavHeader-Menu {
    position: absolute;
    top: 10vh;
    right: 0px;
    background-color: #3d3f4b;
    border-bottom-left-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media (min-height: 1001px) {
    
    .NavHeader-Menu {
        position: absolute;
        top: 5vh;
        right: 0px;
        background-color: #3d3f4b;
        border-bottom-left-radius: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

}

/* ---------------------------------- */
/*  Profile Selector Dropdown          */
/* -----------------------------------*/

.Profile-Selector-Dropdown {
    position: absolute;
    right: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--darkgrey);
    color: white;
    border: 1.5px solid #575C6F;
    font-family: var(--body);
    font-size: var(--fontsize-body);
    text-align: left;
    padding: 10px 15px;
    border-radius: 5px;
    min-width: 250px;
}

/* Small mobile devices */
@media (width < 500px) {

    .Profile-Selector-Dropdown {
        position: absolute;
        left: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: var(--darkgrey);
        color: white;
        border: 1.5px solid #575C6F;
        font-family: var(--body);
        font-size: var(--fontsize-body);
        text-align: left;
        padding: 10px 10px;
        border-radius: 5px;
        min-width: 250px;
    }

}

.Profile-Selector-Dropdown:hover {
    background-color: #3d404d;
}

.Profile-Selector-Dropdown p {
    margin: 0px 15px;
}