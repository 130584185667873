/* ------------------------------------------------------------ */
/** App Styling                                                **/
 /* ------------------------------------------------------------ */

/* Ensures if you change the box-sizing of an element, everything inside the element will inherit the box-sizing of that element */
*,
*:before,
*:after {
    box-sizing: inherit;
}

/* Large devices */
@media (min-height: 1001px) {
    
    .App-Container{
        display: grid;
        grid-template-rows: 5vh 95vh;
        grid-template-areas:
            'App-Container-Nav'
            'App-Container-Body';
        min-width: 365px;
        overflow: hidden;
    }

    .App-Container-Body{
        grid-area: App-Container-Body;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .App-Body {
        padding: 2%;
    }

}

/* Typical phones */
@media (max-height: 1000px) {
    
    .App-Container{
        display: grid;
        grid-template-rows: 10vh 90vh;
        grid-template-areas:
            'App-Container-Nav'
            'App-Container-Body';
        min-width: 320px;
        overflow: hidden;
    }

    .App-Container-Body{
        grid-area: App-Container-Body;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .App-Body {
        padding: 2%;
    }
    
}

/* Small devices */
@media (height <= 700px) {
    
    .App-Container{
        display: grid;
        grid-template-rows: 10vh 90vh;
        grid-template-areas:
            'App-Container-Nav'
            'App-Container-Body';
        min-width: 320px;
        overflow: hidden;
    }

    .App-Container-Body{
        grid-area: App-Container-Body;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .App-Body {
        padding: 5%;
    }

}

.App-Container-Nav{
    grid-area: App-Container-Nav;
    height: 100%;
}
