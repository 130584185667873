.Microsoft-Login-Button {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #373737;
    background-color: #373737;
    color: white;
    border-radius: 5px;
    padding: 8px 15px;
    margin: 0px;
    font-weight: 500;
    box-shadow: 0px 2px 2px rgba(106, 106, 106, 0.5);
    font-size: var(--body);
}

.Microsoft-Login-Button:disabled {
    background-color: rgb(194, 194, 194);
    border: 2.5px solid rgb(184, 184, 184);
    color: white;
}

.Microsoft-Login-Button:hover {
    transform: scale(1.03);
    cursor: pointer;
}