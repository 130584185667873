/* ---------------------------------------------- */
/*  Invite Worker Modal Container                 */
/* ---------------------------------------------- */

/* Small Devices  */
@media (width <=500px) {

    .Invite-Worker-Container {
        display: flex;
        flex-direction: column;
        gap: 15px 0px;
        padding: 40px 25px;
        border-radius: 5px;
        z-index: 10;
        position: absolute;
        min-width: 60%;
        min-height: 20%;
        max-height: 70%;
        top: 17.5%;
        left: calc(50% + 250px);
        transform: translate(calc(-50% - 250px), 0);
        background-color: #FFFFFF;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
        border-top: 15px solid var(--teal);
        overflow-y: auto;
    }

}

/* Large Devices  */
@media (width >=500px) {

    .Invite-Worker-Container {
        display: flex;
        flex-direction: column;
        gap: 15px 0px;
        padding: 40px 25px;
        border-radius: 5px;
        z-index: 10;
        position: absolute;
        width: 45vw;
        min-width: 480px;
        min-height: 20%;
        max-height: 70%;
        top: 17.5%;
        left: calc(50% + 250px);
        transform: translate(calc(-50% - 250px), 0);
        background-color: #FFFFFF;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
        border-top: 15px solid var(--teal);
        overflow-y: auto;
    }

}

/* ---------------------------------------------- */
/*  Remove Worker Modal Container                 */
/* ---------------------------------------------- */

/* Small Devices  */
@media (width <=500px) {

    .Remove-Worker-Container {
        display: flex;
        flex-direction: column;
        gap: 15px 0px;
        padding: 35px 25px;
        border-radius: 5px;
        z-index: 2;
        position: absolute;
        min-width: 60%;
        min-height: 20%;
        max-height: 70%;
        top: 17.5%;
        left: calc(50% + 250px);
        transform: translate(calc(-50% - 250px), 0);
        background-color: #FFFFFF;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
        border-top: 15px solid #D85C5C;
    }

}

/* Large Devices  */
@media (width >=500px) {

    .Remove-Worker-Container {
        display: flex;
        flex-direction: column;
        gap: 15px 0px;
        padding: 35px 25px;
        border-radius: 5px;
        z-index: 2;
        position: absolute;
        width: 45vw;
        min-width: 480px;
        min-height: 20%;
        max-height: 70%;
        top: 17.5%;
        left: calc(50% + 250px);
        transform: translate(calc(-50% - 250px), 0);
        background-color: #FFFFFF;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
        border-top: 15px solid #D85C5C;
    }

}

/* ---------------------------------------------- */
/*  Edit Worker Modal Container                   */
/* ---------------------------------------------- */

/* Small Devices  */
@media (width <=500px) {

    .Edit-Worker-Container {
        display: flex;
        flex-direction: column;
        gap: 15px 0px;
        padding: 40px 25px;
        border-radius: 5px;
        z-index: 10;
        position: absolute;
        min-width: 60%;
        min-height: 20%;
        max-height: 70%;
        top: 17.5%;
        left: calc(50% + 250px);
        transform: translate(calc(-50% - 250px), 0);
        background-color: #FFFFFF;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
        border-top: 15px solid var(--teal);
        overflow-y: auto;
    }

}

/* Large Devices  */
@media (width >=500px) {

    .Edit-Worker-Container {
        display: flex;
        flex-direction: column;
        gap: 15px 0px;
        padding: 40px 25px;
        border-radius: 5px;
        z-index: 10;
        position: absolute;
        width: 45vw;
        min-width: 480px;
        min-height: 20%;
        max-height: 70%;
        top: 17.5%;
        left: calc(50% + 250px);
        transform: translate(calc(-50% - 250px), 0);
        background-color: #FFFFFF;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
        border-top: 15px solid var(--teal);
        overflow-y: auto;
    }

}

