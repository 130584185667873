/* ---------------------------------------------- */
/*  Profile Selector                         */
/* ---------------------------------------------- */

/* Container */
.ProfileModal-Container {
    display: flex;
    flex-direction: column;
    gap: 10px 0px;
    padding: 2.5% 2%;
    width: 50%;
    z-index: 1000;
    position: absolute;
    top: 0.125rem; /* 2px */
    bottom: 0.125rem; /* 2px */
    background-color: #FFFFFF;
    box-shadow: none;
    overflow: auto;
    border-radius: 5px;
    border-top: 15px solid var(--teal);
    border-left: none;
    border-right: none;
    border-bottom: none;
}

/* Modal Header */
.ProfileModal-Header {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    font-weight: 500;
}

/* Large devices use a button */
.ProfileModal-InviteWorker-Button {
    border: 1px solid var(--teal);
    background-color: var(--teal);
    color: white;
    border-radius: 5px;
    padding: 5px 15px;
    font-weight: normal;
    box-shadow: 0px 1px 1px rgba(135, 135, 135, 0.5);
    font-size: var(--body);
    min-width: 100px;
}

/* Small devices use an image */
.ProfileModal-InviteWorker-Img {
    width: 35px;
}

/* ---------------------------------------------- */
/*      Modal Body                                */
/* ---------------------------------------------- */

.ProfileModal-User-List {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0px;
    max-height: 400px;
    overflow: auto;
}

/* Background */
.ProfileModal-User-Background {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    border-left: 10px solid #35394e;
    cursor: pointer;
}