
/* =================================================== */
/*             Profile Form Classes                    */
/* =================================================== */

.Profile-Form {
    display: grid;
}

.Profile-Form-Row label {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.Profile-Form-Row input {
    padding: 20px;
}

.Profile-Form-Row textarea {
    padding: 20px;
}


/* ================================================ */
/*   File Upload Component                          */
/* ================================================ */

/* Onload Container */
.ProfileUploads-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    gap: 0px 15px;
    font-size: 15px;
}

.ProfileUploads-Container input {
    height: 40px;
    font-size: 15px;
}

/* 'Choose File' (inside input [file] */
.ProfileUploads-Container input::-webkit-file-upload-button {
    content: 'Choose File';
    margin: 0px 15px 0px 0px;
    height: 40px;
    border: none;
    border-right: 1px #D8D8D8 solid;
    color: #545454;
    padding: 5px 15px;
    cursor: pointer;
}

/* Error Message */
.ProfileUploads-Error-Message {
    color: red;
    text-align: left;
    padding: 15px 0px;
}

/* File Name Table */
.ProfileUploads-List-Container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #D8D8D8;
    border: 1px #D8D8D8 solid;
    padding: 10px 0 0 0;
    margin-top: 20px;
    background-color: white;
}

.ProfileUploads-List-Container b {
    font-size: 14px;
    color: #4F4F4F;
    padding: 5px 0px 10px 105px;
}

/* File Name Table Row */
.ProfileUploads-List-Row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-top: 1px #D8D8D8 solid;
    width: 100%;
    padding: 1px 0px;
}

.ProfileUploads-List-Row p {
    font-size: 15px;
    width: 100%;
    text-decoration: none;
    padding: 15px 0 15px 0;
    line-height: 20px;
    height: 30px;
    color: #4F4F4F;
}

/* Remove Icon */
.ProfileUploads-List-Delete {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-left: 40px;
    margin-right: 40px;
}

/* View File Icon */
.ProfileUploads-List-ViewFile {
    cursor: pointer;
    padding-right: 50px;
    min-width: 125px;
}

/* Error Fatal */
.ProfileUploads-Error-Container {
    display: flex;
    flex-direction: column;
    gap: 10px 0px;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 500;
    color: rgb(224, 14, 14);
}

.ProfileUploads-Error-Container button {
    width: fit-content;
    padding: 4px 10px;
}

/* ================================================ */
/*   Media Queries                                  */
/* ================================================ */

/* Large devices */
@media (min-width: 800px) {

    .Profile-Form-Row {
        display: flex;
        flex-direction: row;
        padding: 5px;
    }

    .Profile-Form-Row div {
        width: 100%;
    }

    .Profile-Form-Row div:first-child {
        margin-right: 20px;
    }

    .ProfileForm-ZipCode {
        margin-left: 20px;
    }

}

/* Phones */
@media (max-width: 799px) {

    .Profile-Form-Row {
        padding: 15px;
    }

    .ProfileUploads-List-Container b {
        font-size: 14px;
        color: #4F4F4F;
        padding: 5px 55px;
    }

    .ProfileUploads-List-Delete {
        margin: 0px 15px;
    }
    

}