/* =================================================== */
/*             Pre-Qualifications Classes              */
/* =================================================== */

/* Prequalifications Table Header */
.PreQualifications-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 15px;
    align-items: center;
    margin: 20px 0px;
}

.PreQualifications-Header button {
    margin-left: auto;
}

/* Status Labels */
.PreQualifications-Status {
    border-radius: 30px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 5px 15px;
    min-width: 150px;
}

/* =================================== */
/*    Mobile Devices                   */
/* =================================== */

.PreQualifications-Mobile-Filter {
    display: flex;
    flex-direction: column;
    padding: 5px;
    position: absolute;
    top: 300px;
    right: 20px;
    background-color: white;
    z-index: 1;
    border-radius: 10px;
    border: 1px solid var(--teal);
}

.PreQualifications-Mobile-Filter label {
    border-bottom: 1px solid var(--teal);
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}

.PreQualifications-Mobile-Filter label:last-child {
    border-bottom: none;
    padding: 10px;
    margin-bottom: 5px;
}

.PreQualifications-Table-Mobile {
    margin-top: 10px;
    border-radius: 10px;
}

.PreQualifications-Table-Body-Mobile {
    font-weight: none;
    display: flex;
    flex-direction: column;
}

.PreQualifications-Row-Mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
}

.PreQualifications-Mobile-Header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    border-bottom: 1px solid var(--teal);
    font-weight: 600;
    align-items: center;
    flex-direction: row;
}

.PreQualifications-Mobile-Header-Project {
    padding-top: 10px;
    padding-bottom: 10px;
    color: var(--teal);
    cursor: pointer;
}

.PreQualifications-Mobile-Edit {
    cursor: pointer;
    margin-right: 10px;
}

/* Large Mobile Devices */
@media (width >=460px) {

    .PreQualifications-Row-Mobile div {
        display: grid;
        grid-template-columns: 100px 1fr;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 2px;
        padding-right: 2px;
        gap: 20px;
    }

}


/* Small Mobile Devices */
@media (width <=460px) {

    .PreQualifications-Row-Mobile div {
        display: grid;
        padding: 10px;
    }

}