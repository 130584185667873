/* =================================================== */
/*   Project Page Classes                              */
/* =================================================== */

.Project-Container {
    display: grid;
    grid-template-rows: 90px 1fr;
    grid-template-areas:
        'Header'
        'Body';
}

.Project-Header {
    grid-area: Header;
}

.Project-Body {
    grid-area: Body;
}

/* =================================================== */
/*   Prequalification Form Classes                     */
/* =================================================== */
/* --------------------------------------------------- */
/*   Prequalification Form Header                      */
/* --------------------------------------------------- */

.PreQ-Form-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    border-bottom: 1px solid #C2C2C2;
    padding: 20px 15px;
    align-items: center;
}

/* Container with Blue Left Border */
.PreQ-Form-Section-Container {
    background-color: white;
    border-radius: 5px 0px 0px 5px;
    margin-top: 20px;
    padding: 25px 30px;
    border: 1px solid lightgray;
    border-left: 6px solid #6EB7CE;
    font-size: 15px;
    line-height: 1.6;
}

/* --------------------------------------------------- */
/*   Prequalification Form Status Pane                 */
/* --------------------------------------------------- */

.PreQ-Form-Status-Pane {
    padding: 20px 30px;
    margin: 30px 0px 10px 0px;
    color: #424242;
}

.PreQ-Form-Status-Pane h6 {
    font-weight: 600;
}

.PreQ-Form-Status-Pane p {
    margin: 0px;
}

/* --------------------------------------------------- */
/*   Prequalification History Pane                     */
/* --------------------------------------------------- */

.PreQ-History-Pane {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.PreQ-History-Pane-Table {
    table-layout: fixed;
    width: 100%;
    text-align: left;
    font-size: 15px;
    border: 0.5px solid lightgray;
    margin: 30px 0px 25px 0px;
}


table.PreQ-History-Pane-Table th {
    padding: 20px;
    font-weight: 600;
    border-bottom: 0.5px solid lightgray;
    color: #4F4F4F;
}

table.PreQ-History-Pane-Table td {
    padding: 15px 20px;
}

table.PreQ-History-Pane-Table tr {
    padding: 10px 0px;
}


/* --------------------------------------------------- */
/*   Prequalification Form Buttons                     */
/* --------------------------------------------------- */


.PreQ-Form-Container-Buttons button {
    width: 145px;
    height: 40px;
    margin: 5px;
    margin-top: 30px;
}

/* --------------------------------------------------- */
/*   Prequalification Form Existing DCAC               */
/* --------------------------------------------------- */

.PreQ-Form-ExistingDCAC {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* ------------------------------------------------ */
/*   Upload Existing DCAC                           */
/* ------------------------------------------------ */

/* Onload Container */
.PreQ-Uploads-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    gap: 0px 15px;
    font-size: 15px;
}

.PreQ-Uploads-Container input {
    width: 100%;
    height: 40px;
    font-size: 15px;
}

/* 'Choose File' (inside input [file] */
.PreQ-Uploads-Container input::-webkit-file-upload-button {
    content: 'Choose File';
    margin: 0px 15px 0px 0px;
    height: 40px;
    border: none;
    border-right: 1px #D8D8D8 solid;
    color: #545454;
    padding: 5px 15px;
    cursor: pointer;
}

/* Error Message */
.PreQ-Uploads-Error-Message {
    color: red;
    text-align: left;
    padding: 15px 0px;
}

/* Help Text */
.PreQ-Uploads-Help-Text {
    font-size: 12px;
    margin: 8px 0px 0px 0px;
    color: #545454;
}

/* File Name Table */
.PreQ-Uploads-List-Container {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #D8D8D8;
    border: 1px #D8D8D8 solid;
    padding: 10px 0 0 0;
    margin-top: 20px;
}

.PreQ-Uploads-List-Container b {
    font-size: 14px;
    color: #4F4F4F;
    padding: 5px 0 10px 105px;
}

/*  File Name Table Row */
.PreQ-Uploads-List-Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px #D8D8D8 solid;
    width: 100%;
    padding: 10px;
    align-items: center;
    text-align: center;
    gap: 10px
}

.PreQ-Uploads-Container p {
    font-size: 15px;
    width: 100%;
    text-decoration: none;
    padding: 15px 0 15px 0;
    line-height: 20px;
    height: 30px;
    color: #4F4F4F;
}

/* Remove Icon */
.PreQ-Uploads-List-Delete {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-left: 40px;
    margin-right: 40px;
}

/* View File Icon */
.PreQ-Uploads-List-ViewFile {
    cursor: pointer;
    min-width: 125px;
}

/* Error Fatal */
.PreQ-Uploads-Error-Container {
    display: flex;
    flex-direction: column;
    gap: 10px 0px;
    font-size: 15px;
    line-height: 1.6;
    font-weight: 500;
    color: rgb(224, 14, 14);
}

.PreQ-Uploads-Error-Container button {
    width: fit-content;
    padding: 4px 10px;
}

/* --------------------------------------------------- */
/*   Privacy Policy Pane Styling                       */
/* --------------------------------------------------- */

.PreQ-Form-PrivacyPolicy-Container {
    display: flex;
    flex-direction: row;
    align-items: top;
    gap: 10px;
    font-size: 15px;
}

/* --------------------------------------------------- */
/*   Prequalification Form No DCAC                     */
/* --------------------------------------------------- */

/* Small Devices */
@media (width <= 450px) {
    
    .PreQ-Form-Expandable-Pane {
        display: grid;
        grid-template-columns: 20px 3.2fr;
        grid-template-areas:
            'Step Heading'
            'Content Content';
        gap: 0px 10px;
        border-bottom: 1px solid lightgray;
        border-top: 1px solid lightgray;
        padding: 20px 30px;
        align-items: center;
        justify-content: center;
    }

    .PreQ-Form-Expandable-Pane-ExpandCollapseIcon {
        display: none;
    }
}

/* Large Devices */
@media (width >= 451px) {
    
    .PreQ-Form-Expandable-Pane {
        display: grid;
        grid-template-columns: 20px 1fr 40px;
        grid-template-areas:
            'Step Heading ExpandCollapseIcon'
            'Content Content Content';
        gap: 0px 10px;
        border-bottom: 1px solid lightgray;
        border-top: 1px solid lightgray;
        padding: 20px 30px;
        align-items: center;
        justify-content: center;
    }

    .PreQ-Form-Expandable-Pane-ExpandCollapseIcon {
        grid-area: ExpandCollapseIcon;
        cursor: pointer;
        width: 25px;
    }
    
}

.PreQ-Form-Expandable-Pane-Step {
    grid-area: Step;
    width: 25px;
    justify-self: center;
}

.PreQ-Form-Expandable-Pane-Heading {
    grid-area: Heading;
    font-weight: 500;
}


/* Small Devices */
@media (width <= 451px) {

    .PreQ-Form-Expandable-Pane-Content {
        grid-area: Content;
        padding: 30px;
        font-size: 15px;
    }

}


/* Large Devices */
@media (width >= 451px) {

    .PreQ-Form-Expandable-Pane-Content {
        grid-area: Content;
        padding: 3% 4%;
        font-size: 15px;
    }

}



/* --------------------------------------------------- */
/*   Application Form Styling                          */
/* --------------------------------------------------- */

.PreQ-Form-Application-Container {
    display: flex;
    flex-direction: column;
    gap: 25px 15px;
    font-size: 15px;
}

.PreQ-Form-Application-Question {
    font-size: 15px;
    font-weight: 500;
    color: #545454;
    align-items: center;
    margin: 15px 0px;
}

.PreQ-Form-Application-Input {
    font-size: 15px;
    margin: 0px 15px;
    border: 1px solid #aaaaaa;
    border-radius: 5px;
    padding: 8px 15px;
}

.PreQ-Form-Application-Input-Error {
    font-size: 15px;
    margin: 0px 15px;
    border: 1px solid var(--darkred);
    border-radius: 5px;
    padding: 8px 15px;
    box-sizing: border-box;
}

/* Single Column Question Container */

.PreQ-Form-Application-Single-Column-Grid {
    display: grid;
    grid-template-columns: 0.5fr;
    grid-template-rows: 1fr;
    align-items: center;
    gap: 5px 10px;
}

/* Dual Column Question Container */

/* Small Devices --> SINGLE COLUMN! */
@media (width <= 800px) {

    .PreQ-Form-Application-Dual-Column-Grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 0px 10px;
        align-items: center;
    }

    .PreQ-Form-Application-Names{
        display: flex;
        flex-direction: column;
    }

    .PreQ-Form-Application-Names:last-child{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    
}

/* Large Devices --> DUAL Column! */
@media (width >= 801px) {

    .PreQ-Form-Application-Dual-Column-Grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 0px 10px;
        align-items: center;
    }

    .PreQ-Form-Application-Names{
        display: flex;
        flex-direction: column;
    }
    
}

.PreQ-Form-Application-Dual-Column-Grid label {
    line-height: 1.6;
    letter-spacing: 0.75px;
    color: #6C6C6C;
    font-weight: 600;
    font-size: 14px;
    margin: 0px 15px;
}

/* Full Width Grid */

.PreQ-Form-Application-Full-Width-Grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    gap: 5px 10px;
}

/* Required Uploads */

.PreQ-Form-Application-Required-Uploads {
    padding: 35px 15px 0px 15px;
    font-size: 15px;
}

.PreQ-Form-Application-Required-Uploads h6 {
    color: #6C6C6C;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.75px;
}

.PreQ-Form-Application-Required-Uploads p {
    font-size: 15px;
}

.PreQ-Form-Application-Required-Uploads ul {
    margin: 0px;
    font-size: 15px;
}

/* Upload Options */

.PreQ-Form-Application-Upload-Option-Container {
    display: grid;
    grid-template-columns: 30px 1fr;
    grid-template-rows: 1fr;
    align-items: center;
}

.PreQ-Form-Application-Upload-Option {
    border-radius: 50%;
    background-color: var(--teal);
    color: white;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 1px 0px;
    font-size: 14px;
    align-self: start;
}

.PreQ-Form-Application-ViewFile {
    width: 200px;
    cursor: pointer;
    padding-right: 50px;
}

/* Declaration */

.PreQ-Form-Application-Declaration {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px 15px;
    font-size: 15px;
}

.PreQ-Form-Application-Declaration h6 {
    color: #6C6C6C;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.75px;
}

.PreQ-Form-Application-Declaration div {
    display: flex;
    flex-direction: row;
    gap: 15px;
    font-size: 14px;
}

.PreQ-Form-Application-Declaration p {
    margin: 0px;
}

/* --------------------------------------------------- */
/*   Additional Info Styling                            /
/* --------------------------------------------------- */

.PreQ-Form-AddInfo-Container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 15px;
}

.PreQ-Form-AddInfo-Container h6 {
    color: #6C6C6C;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.75px;
}

/* Small Devices */
@media (width <= 600px) {

    .PreQ-Form-AddInfo-Comments-Container {
        display: grid;
        gap: 0px 30px;
        grid-template-areas:
            'comments'
            'buttons';
        padding-bottom: 20px;
        font-size: 15px;
    }

    .PreQ-Form-AddInfo-Comments {
        grid-area: comments;
        display: flex;
        flex-direction: column;
    }

    .PreQ-Form-AddInfo-Comments-Container-Buttons {
        grid-area: buttons;
        display: flex;
        flex-direction: column;
    }

    /* Nominated Office Styling */
    .PreQ-Form-NominatedOffice-Container{
        padding-left: 10px;
        display: flex;
        flex-direction: column;
    }

    .PreQ-Form-NominatedOffice-Select {
        font-size: var(--body);
        color: #929292;
        height: 42px;
        width: 200px;
        border-radius: 5px;
        border: 1px #D8D8D8 solid;
        padding: 10px;
        margin-top: 10px;
    }
    
    .PreQ-Form-NominatedOffice-Select:active {
        outline: 1px #35394e7e solid;
    }
    
    .PreQ-Form-NominatedOffice-Select:disabled {
        outline: 1px solid #aaaaaa;
    }
    
    .PreQ-Form-NominatedOffice-Select:focus {
        outline: 1px #35394e7e solid;
    }
    
    .PreQ-Form-NominatedOffice-Select:invalid {
        outline: 2px #ff15007e solid;
    }
    
    .PreQ-Form-NominatedOffice-Select-Error {
        font-size: var(--body);
        color: #929292;
        height: 42px;
        width: 150px;
        border-radius: 5px;
        border: 1px solid var(--darkred);
        padding: 10px;
    }
    
}

/* Large Devices */
@media (width >= 601px) {

    .PreQ-Form-AddInfo-Comments-Container {
        display: grid;
        grid-template-columns: 1.5fr 0.5fr;
        gap: 0px 30px;
        grid-template-areas:
            "comments buttons";
        padding-bottom: 20px;
        font-size: 15px;
    }
    
    .PreQ-Form-AddInfo-Comments {
        grid-area: comments;
        display: flex;
        flex-direction: column;
    }

    .PreQ-Form-AddInfo-Comments-Container-Buttons {
        grid-area: buttons;
        display: flex;
        flex-direction: column;
    }

    /* Nominated Office Styling */
    .PreQ-Form-NominatedOffice-Container{
        padding-left: 10px;
    }

    .PreQ-Form-NominatedOffice-Select {
        font-size: var(--body);
        color: #929292;
        height: 42px;
        width: 200px;
        border-radius: 5px;
        border: 1px #D8D8D8 solid;
        padding: 10px;
        margin-right: 10px;
    }
    
    .PreQ-Form-NominatedOffice-Select:active {
        outline: 1px #35394e7e solid;
    }
    
    .PreQ-Form-NominatedOffice-Select:disabled {
        outline: 1px solid #aaaaaa;
    }
    
    .PreQ-Form-NominatedOffice-Select:focus {
        outline: 1px #35394e7e solid;
    }
    
    .PreQ-Form-NominatedOffice-Select:invalid {
        outline: 2px #ff15007e solid;
    }
    
    .PreQ-Form-NominatedOffice-Select-Error {
        font-size: var(--body);
        color: #929292;
        height: 42px;
        width: 150px;
        border-radius: 5px;
        border: 1px solid var(--darkred);
        padding: 10px;
    }

}

.PreQ-Form-AddInfo-Comments textarea {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}

.PreQ-Form-AddInfo-Comments-Container button {
    width: 150px;
    height: 40px;
    padding: 5px 15px 5px 0px;
    margin-right: 10px;
    margin-top: 10px;
}

/* --------------------------------------------------- */
/*   Radio Buttons                                     */
/* --------------------------------------------------- */

.PreQ-Radio-Buttons-Container {
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
}

/* --------------------------------------------------- */
/*  Checkboxes                                         */
/* --------------------------------------------------- */

/* Checkbox Container */
.PreQ-Form-Checkbox-Container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.PreQ-Form-Checkbox-Container input {
    display: none;
}

/* Create a custom checkbox */
.PreQ-Form-Checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1.5px solid #C0C0C0;
    border-radius: 2px;
}

/* Error State for Checkbox  */
.PreQ-Form-Checkbox-Error {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1.5px solid var(--darkred);
    border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.PreQ-Form-Checkbox-Container input:checked~.PreQ-Form-Checkbox {
    background-color: var(--teal);
}

/* Create the checkmark/indicator (hidden when not checked) */
.PreQ-Form-Checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.PreQ-Form-Checkbox-Container input:checked~.PreQ-Form-Checkbox:after {
    display: block;
}

/* Style the checkmark/indicator */
.PreQ-Form-Checkbox-Container .PreQ-Form-Checkbox:after {
    left: 8px;
    top: 4px;
    width: 7px;
    height: 12px;
    border: 1px solid white;
    border-width: 0 3px 3px 0;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}