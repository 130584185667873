.PageHeader-Container {
    display: flex;
    flex-direction: column;
    gap: 3px 0px;
    padding: 25px 35px;
    border-top: 10px solid #84C1D4;
    border-radius: 5px;
    border-bottom: 1px solid rgb(231, 231, 231);
    background-color: var(--fadedgrey);
    width: 100%;
}

.PageHeader-Container div {
    color: #5E5454;
    font-size: 15px;
}